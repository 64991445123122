import { graphql } from 'gatsby';
import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import RenderBlocks, { Blocks } from 'utils/RenderModules';

type CasePageProps = {
  data: GatsbyTypes.CasePageQueryQuery;
};

const CasePage: React.FC<CasePageProps> = ({ data }) => {
  if (!data.datoCmsCase) return null;

  return (
    <>
      <HelmetDatoCms seo={data.datoCmsCase?.seoMetaTags} />
      <RenderBlocks data={data.datoCmsCase.modules as Blocks[]} />
    </>
  );
};

export default CasePage;

export const query = graphql`
  query CasePageQuery($id: String!) {
    datoCmsCase(id: { eq: $id }) {
      id
      title
      seoMetaTags {
        tags
      }
      modules {
        ...Hero
        ...BigHero
        ...Diagram
        ...IconTextList
        ...MediaComponent
        ...Spacing
        ...TwoTextColumn
        ...Testimonial
      }
    }
  }
`;
